import {ref, reactive, toRefs, computed,watch,defineComponent,onBeforeMount,onMounted,getCurrentInstance,provide,nextTick} from 'vue';
import VideoCardUtil,{IVideoCardDataObj} from './videoCardUtil';
export default defineComponent({
    name:'VideoCard',
    title:'视频',
    modelType:'card',
    fullscreen: false,
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        provide('uploadOwner', proxy);
        let dataObj:IVideoCardDataObj=reactive<IVideoCardDataObj>({
            utilInst:{} as any,
            formRef:null,
            disabled:false,
            refMap:new Map(),
            //卡片传给dialog的初始化参数
            compParams: {
                hasDetails: false,
                modelPath: utils.ToolsProviderApi.buildUrl('/video')
            },
            //表单
            form:{},
            //表单验证规则
            rules: {
                videoTypeId: [utils.UtilPub.commonValidRule('请选择视频类别')],
                name: [utils.UtilPub.commonValidRule('请输入名称')],
            },
            otherParams:{
                videoTypeData:[]
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new VideoCardUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed---------------------------
        //下拉select数据集获取（数据集构造和cascader类似）
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'videoTypeId'==params.comboId){
                    return dataObj.otherParams.videoTypeData;
                }
            }
        })
        //---------------------------selectOnchange--------------------------
        const selectOnChange=(newValue:string,selectId:string)=>{
            if('videoTypeId'==selectId){}

        }
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(res:any,addOrLoad:string,engineInst:any)=>{

        }
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            return true;
        }
        const buildUploadInitParams=computed(()=>{
            return (params:any) => {
                return Object.assign({
                    belongMaxId:dataObj.form.id,
                    fileSize:100,
                    saveType:1,
                    limitNum:1,
                    // accept:'.mp4,.MP4',
                    showFileList:true
                },params)
            }
        })
        const handlePreview=(file:any)=>{
            utils.Tools.configBox({
                message:'即将下载该附件，是否继续？',
                sureFn: ()=>{
                    let params={id:file.id,fileName:file.name};
                    utils.Api.downLoad(params);
                }
            });
        }
        return{
            ...toRefs(dataObj),proxy,comboSelect,selectOnChange,beforeOpen,beforeSaveHandler,buildUploadInitParams,handlePreview
         
        }
    }
});