import BaseBean from "@/utils/BaseBean";

export interface IVideoCardDataObj {
    utilInst:VideoCardUtil
    formRef:any
    disabled:boolean
    refMap:Map<string,any>
    compParams: any
    form:any
    rules:TFormRule
    otherParams:any
}
export default class VideoCardUtil extends BaseBean{
    public dataObj:IVideoCardDataObj;

    constructor(proxy:any,dataObj:IVideoCardDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        this.dataObj.otherParams.videoTypeData=await this.utils.ToolsProviderApi.getAllVideoType();
    }
}