import BaseBean from "@/utils/BaseBean";

export interface IVideoListDataObj {
    utilInst:VideoListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
    otherParams: any
}

export default class VideoListUtil extends BaseBean{
    public dataObj:IVideoListDataObj

    constructor(proxy:any,dataObj:IVideoListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        this.dataObj.otherParams.videoTypeData=await this.utils.ToolsProviderApi.getAllVideoType();
    }
}